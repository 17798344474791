<template>
<div class="content-wrapper">
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" />

  <div class="content">

    <div class="card">
      <validation-observer ref="VFormPelayananInformasiObat">
        <b-form @submit.prevent="doSubmit()">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4">
                <h6 class="card-title font-weight-semibold">Form Pelayanan Informasi Obat</h6>
              </div>
            </div>
          </div>
          <div class="card-body p-3">
            <h6 class="font-weight-semibold">Identitas Penanya <span class="text-danger">*</span></h6>
            <div class="row">
              <div class="form-group col-lg-3">
                <label>Nama</label>
                <b-form-input type="text" name="NamaLengkap" id="NamaLengkap" class="form-control" placeholder="Nama Lengkap" v-model="row.name" @change="updateRow(row)" />

                <VValidate name="Nama" v-model="row.name" :rules="mrValidation.name" />
              </div>
              <div class="form-group col-md-3">
                <label>No Selular <span class="text-danger">*</span></label>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon2">+62</span>
                  </div>
                  <b-form-input type="tel" class="form-control" placeholder="" :formatter="Formatter.number" v-model="row.phone_number" @change="updateRow(row)" />
                </div>

                <VValidate name="No Selular" v-model="row.phone_number" :rules="mrValidation.phone_number" />
              </div>

              <div class="form-group col-md-3">
                <label>Hubungan Keluarga <small class="txt_mandatory">*</small></label>

                <v-select :options="$parent.mrHubunganKeluarga" :reduce="v => v.value" label="label" v-model="row.hubungan_keluarga" @input="updateRow(row)" />
                <VValidate name="Hubungan Keluarga" v-model="row.hubungan_keluarga" :rules="mrValidation.hubungan_keluarga" />
              </div>

              <div class="form-group col-md-3" v-if="row.hubungan_keluarga == 'LAINNYA'">
                <label>Lainnya <small class="txt_mandatory">*</small></label>

                <b-form-input type="text" class="form-control" placeholder="Lainnya" v-model="row.other_hubungan_keluarga" @change="updateRow(row)" />
                <VValidate name="Lainnya" v-model="row.other_hubungan_keluarga" :rules="{ required: 1 }" />
              </div>
              
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="" for="">Metode</label>
                      <b-form-radio-group @change="updateRow(row)" :id="`metode`" v-model="row.metode" :name="`metode`" text-field="label" :options="$parent.mrMetode">

                      </b-form-radio-group>

                      <VValidate name="Metode" v-model="row.metode" :rules="mrValidation.metode" />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="" for="">Uraian Pertanyaan</label>
                      <b-form-textarea name="Jenis Pertanyaan" id="Jenis Pertanyaan" rows="2" class="form-control" placeholder="Jenis Pertanyaan" v-model="row.uraian_pertanyaan" @change="updateRow(row)"></b-form-textarea>

                      <VValidate name="Uraian Pertanyaan" v-model="row.uraian_pertanyaan" :rules="mrValidation.uraian_pertanyaan" />
                    </div>
                  </div>
                  <div class="col-md-12 mb-2">
                    <table class="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th colspan="2">Jenis Pertanyaan</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(v, k) in mrJenisPertanyaan" :key="`mrJP-${k}`">
                          <td v-for="(dt, kdt) in v" :key="`dt-${k}-${kdt}`">
                            <b-form-checkbox :id="`mjpc-${k}-${kdt}`" v-model="row.jenis_pertanyaan" :name="`mjpc-${k}-${kdt}`" :key="`mjpc-${k}-${kdt}`" :value="dt.value == 'OTHER' ? { ...dt, note: getValueCheckbox(dt.value, 'jenis_pertanyaan', 'note')||'' } : { ...dt }" @change="updateRow(row)">
                              {{ dt.label }}

                              <div class="input-group mt-2" v-if="dt.value == 'OTHER' && checkDataRow(dt.value, 'jenis_pertanyaan')">
                                <b-form-input class="form-control" :placeholder="dt.placeholder" @change="updateValueCheckbox($event, dt.value, 'jenis_pertanyaan'); updateRow(row);" :value="dt.note" :formatter="dt.formatter ? Formatter[dt.formatter] : Formatter.default" />
                              </div>
                            </b-form-checkbox>
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    <VValidate name="Jenis Pertanyaan" :value="(row.jenis_pertanyaan||[]).length > 0 ? true : null" :rules="mrValidation.jenis_pertanyaan" />
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="" for="">Jawaban</label>
                      <b-form-textarea name="Jenis Pertanyaan" id="Jenis Pertanyaan" rows="2" class="form-control" placeholder="Jawaban" v-model="row.jawaban" @change="updateRow(row)"></b-form-textarea>

                      <VValidate name="Jawaban" v-model="row.jawaban" :rules="mrValidation.jawaban" />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="" for="">Referensi</label>
                      <b-form-textarea name="Jenis Pertanyaan" id="Jenis Pertanyaan" rows="2" class="form-control" placeholder="Referensi" v-model="row.referensi" @change="updateRow(row)"></b-form-textarea>

                      <VValidate name="Referensi" v-model="row.referensi" :rules="mrValidation.referensi" />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="" for="">Penyampaian Jawaban </label>
                      <b-form-radio-group @change="updateRow(row)" :id="`penyampaian_jawaban`" v-model="row.penyampaian_jawaban" :name="`penyampaian_jawaban`" text-field="label" :options="$parent.mrPenyampaianJawaban">

                      </b-form-radio-group>
                      <VValidate name="Penyampaian Jawaban" v-model="row.penyampaian_jawaban" :rules="mrValidation.penyampaian_jawaban" />

                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group">
                      <label class="" for="">Apoteker yang menjawab</label>
                      <b-form-input type="text" class="form-control" v-model="row.apoteker_menjawab" @change="updateRow(row)" />

                      <VValidate name="Apoteker yang manjawab" v-model="row.apoteker_menjawab" :rules="mrValidation.apoteker_menjawab" />
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="" for="">Tanggal </label>
                      <datepicker input-class="form-control transparent" placeholder="Tanggal Operasi" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.date" @change="updateRow(row)" />

                      <VValidate name="Tanggal" v-model="row.date" :rules="mrValidation.date" />
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label class="" for="">Waktu </label>
                      <!-- <b-form-input type="text" class="form-control custom-timepicker"> -->

                      <vue-timepicker manual-input format="HH:mm" input-class="form-control" v-model="row.time" @change="updateRow(row)">
                      </vue-timepicker>

                      <VValidate name="Waktu" v-model="row.time" :rules="mrValidation.time" />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="" for="">Metode Jawaban </label>
                      <b-form-radio-group @change="updateRow(row)" :id="`metode_jawaban`" v-model="row.metode_jawaban" :name="`metode_jawaban`" text-field="label" :options="$parent.mrMetodeJawaban">

                      </b-form-radio-group>
                      <VValidate name="Metode Jawaban" v-model="row.metode_jawaban" :rules="mrValidation.metode_jawaban" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="d-flex flex-column align-items-end">
              <button class="btn btn-labeled btn-labeled-left btn-primary">
                <b><i class="icon-paperplane"></i></b>
                <span>Kirimkan</span>
              </button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </div>
</div>
</template>

<script>
import Formatter from '@/libs/Formatter.js'
import Datepicker from 'vuejs-datepicker'

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import Gen from '@/libs/Gen.js'
import PatientInfo from '@/components/Ranap/PatientInfo.vue'

const _ = global._
export default {
  props: {},
  components: {
    Datepicker,
    PatientInfo,
    VueTimepicker
  },
  data() {
    return {
      patientData: {},
      loading: {
        patientInfo: false,
      }
    }
  },
  mounted() {
    this.getPatientInfo()
  },
  methods: {
    getValueCheckbox(keyValue, keyData, keyProperty) {
      const findIndex = this.row[keyData].findIndex(dt => dt.value == keyValue)
      if(this.row[keyData][findIndex]) return this.row[keyData][findIndex][keyProperty]
      return null
    },
    updateValueCheckbox(value, keyValue, keyData, data) {
      // this.$set(this.row[keyData], keyData, data.map(dt => {
      //   if(dt.value === keyValue) dt.note = value

      //   return { ...dt }
      // }))

      const findIndex = this.row[keyData].findIndex(dt => dt.value == keyValue)
      this.$set(this.row[keyData][findIndex], 'note', value)
    },
    checkDataRow(value, keyData) {
      const indexKeyData = this.row[keyData].findIndex(dt => dt.value === value)
      return indexKeyData >= 0
    },
    updateRow(data) {
      if (data.is_draft == 'N') return

      setTimeout(() => {
        Gen.apiRest(
          "/do/" + this.$parent.modulePage, {
            data: {
              ...data,
              type: "update-pelayanan-informasi",
              id_registrasi: this.$route.params.pageSlug
            }
          },
          "POST"
        )
      }, 1000)
    },
    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },
    doSubmit() {
      this.$refs['VFormPelayananInformasiObat'].validate().then(success => {
        if (!success) return false
        this.$swal.fire({
          icon: "warning",
          title: "<h2 class='font-weight-bold'>Sudah Yakin Dengan Data yang Diisi?</h2>",
          html: `
              <span style="font-size: 16px">
                Pastikan untuk mengisikan data yang sesuai, karena data yang sudah disubmit sudah tidak dapat diubah
              </span>              
            `,
          showCancelButton: true,
          cancelButtonText: "Cek Ulang",
          confirmButtonText: "Lanjutkan & Submit"
        }).then(button => {
          if (button.isConfirmed) {
            const payload = {
              ...this.row,
              id_registrasi: this.$route.params.pageSlug
            }
            this.$parent.doSubmitPelayanan({
              ...payload,
              type: 'submit-pelayanan-informasi-obat'
            })
          }
        })
      })
    }
  },
  computed: {
    row() {
      return this.$parent.row
    },
    mrValidation() {
      return this.$parent.mrValidation
    },
    Formatter() {
      return Formatter
    },

    mrJenisPertanyaan() {
      return _.chunk(this.$parent.mrJenisPertanyaan, 2)
    },

    isQuestionMateri() {
      return (this.row.materi || []).find(dtMateri => dtMateri.value === "ADD_QUESTION")
    }
  },
  watch: {
    $route() {
      this.getPatientInfo()
    },
    'row.signature'(v) {
      this.setSignature(v)
    },
    'isQuestionMateri'(v) {
      if (!v) this.$set(this.row, 'question', null)
    }
  }
}
</script>
