<template>
<div class="content-wrapper">

  <div v-if="isList" class="content pt-0">
    <bo-page-title />
    <div class="card">
      <div class="card-header bg-white">
        <div class="row align-items-center">
          <div class="col-md-5">
            <div class="form-row align-items-center">
              <div class="col-md-4">
                <h5 class="card-title font-weight-semibold">Daftar Pasien</h5>
              </div>
              <div class="col-md-8">
                <div class="form-group mb-0">
                  <div class="input-group">
                    <date-range-picker ref="picker" :locale-data="datePickerConfig.locale" :autoApply="datePickerConfig.autoApply" v-model="dateRange" :opens="'right'" :ranges="datePickerConfig.ranges" @update="updateValues">
                      <template v-slot:input="picker">
                        {{ picker.startDate | date }} - {{ picker.endDate | date }}
                      </template>
                    </date-range-picker>
                    <div class="input-group-append calendar-group">
                      <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-header">
        <div class="row">
          <div class="col-lg-9">
            <div class="row gx-1">
              <div class="col-md">
                <div class="row gx-1">
                  <div class="col-md-auto">
                    <div class="form-group mb-0">
                      <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover.right><i class="icon-spinner11"></i></button>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Jaminan -- " @input="doFill" v-model="filter.status_jaminan" :options="Config.mr.statusJaminan" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0">
                      <v-select placeholder=" -- Pilih Cara Masuk -- " @input="doFill" v-model="filter.cara_masuk" :options="Config.mr.caraMasuk" label="text" :reduce="v=>v.value"></v-select>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
              <input class="form-control" v-model="filter.search" @input="doSearch" placeholder="Ketik Nama/No. RM Pasien" />
              <div class="form-control-feedback">
                <i class="icon-search4 text-indigo"></i>
              </div>

              <b-button class="ml-1 d-inline-flex align-items-center" variant="outline-success" id="resetBtn" @click="doResetData()">Reset</b-button>
            </div>
          </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
          <thead>
            <tr>
              <th>NO.</th>
              <th>NO. KEDATANGAN</th>
              <th>TANGGAL MASUK</th>
              <th>NAMA/NO KTP</th>
              <th>DOKTER DPJP</th>
              <th>NO RM</th>
              <th>RUANGAN</th>
              <th>JENIS RAWAT INAP</th>
              <th>TANGGAL LAHIR</th>
              <th>USIA</th>
              <th>PPJP</th>
              <th>STATUS</th>
              <th>AKSI</th>
            </tr>
          </thead>

          <tbody v-if="(dataList||[]).length">
            <template v-for="(v,k) in (dataList||[])">
              <tr :key="k">
                <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                <td>
                  <div class="text-success-700 font-weight-semibold">{{v.aranr_reg_code||"-"}}</div>
                </td>
                <td>
                  <div>{{v.aranres_tanggal_periksa | moment("DD MMMM YYYY")}}</div>
                </td>
                <td>
                  <div>
                    <a href="javascript:;" @click="toMonitoring(v.aranr_id)" class="font-weight-semibold border-bottom" v-b-tooltip.hover title="Periksa Pasien"> {{v.ap_fullname||"-"}}</a>
                    <br>
                    {{v.ap_code||"-"}}</div>
                  <small class="text-secondary">Terdaftar {{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</small>
                </td>
                <td>{{v.bu_full_name||"-"}}</td>
                <td>
                  <div class="font-weight-semibold">{{v.ap_code||"-"}}</div>
                  <span>dari {{v.aranres_cara_masuk == 'RAJAL'?'POLI': v.aranres_cara_masuk == 'BBL' ? 'BBL':'UGD'}}</span>
                  <div class="text-success-700 font-weight-semibold">
                    {{v.no_reg||"-"}}
                  </div>
                </td>
                <td>
                  <div class="font-weight-semibold">{{v.mrank_name||"-"}}</div>
                  <small><span>{{v.mranb_name||"-"}}</span> - <span>{{v.mrankel_name||"-"}}</span></small>
                </td>
                <td>
                  <div class="font-weight-semibold">{{getConfigDynamic(Config.mr.jenisRawatInap,v.aranres_jenis_ranap)||"-"}}</div>
                </td>
                <td>
                  <div class="font-weight-semibold" v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY") }}</div>
                  <span v-else> - </span>
                </td>
                <td>
                  <div class="font-weight-semibold">
                    {{v.ap_usia_with_ket || "-"}}
                  </div>
                </td>
                <td>
                  {{v.nama_ppjp||"-"}}
                </td>
                <td>
                  <template>
                    <div :class="`badge ${v.arans_color} mb-1`">{{v.arans_name||"-"}}</div> <br />

                    <div class="badge badge-danger mb-1">{{ v.arcptlsp_type }}</div>
                  </template>

                </td>
                <td>
                  <a href="javascript:;" v-b-tooltip.hover.left title="Lihat Detail" v-if="moduleRole('view')" class="btn btn-icon rounded-round btn-sm alpha-info border-info" @click="openDetail(v)">
                    <i class="icon-file-eye"></i>
                  </a>

                  <a href="javascript:;" v-if="moduleRole('retur_obat')" @click="openReturObat(v)" class="btn btn-icon rounded-round btn-sm alpha-danger border-danger text-danger-800" v-b-tooltip.hover.left title="Retur Obat">
                    <i class="fa-solid fa-pills"></i>
                  </a>

                  <router-link v-if="moduleRole('pelayanan_informasi_obat')" :to="{ name: modulePage, params: { pageSlug: v.aranr_id } }" class="btn btn-icon rounded-round btn-sm alpha-info border-info text-info-800" v-b-tooltip.hover.left title="Pelayanan Informasi Obat">
                    <i class="fa-solid fa-pills"></i>
                  </router-link>

                  <a href="javascript:;" v-if="moduleRole('download_pelayanan') && v.aranpio_is_draft == 'N'" :to="{ name: modulePage, params: { pageSlug: v.aranr_id } }" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800" v-b-tooltip.hover.left title="Download Pelayanan Informasi Obat" @click="downloadPelayanan(v)">
                    <i class="icon-file-text"></i>
                  </a>
                </td>

              </tr>
            </template>
          </tbody>
          <tbody v-if="data.data.length == 0">
            <tr>
              <td colspan="99">
                <div class="text-center">
                  <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                    <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!data.data">
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
            <tr>
              <td colspan="99">
                <div class="skeletal-comp"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
          <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-left"></i>
          </a>
          <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
            <i class="icon-chevron-right"></i>
          </a>
        </div>
      </div>

      <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">
        <b-pagination class="mb-0" v-model="pageNo" :per-page="data.per_page" :total-rows="data.total" />
      </b-card-footer>

    </div>

    <b-modal hide-footer id="detail" size="lg" title="Detail">
      <div class="row">
        <div class="col-md-12">
          <div class="card-header bg_head_panel">
            <h6 class="card-title font-weight-semibold">Identitas Penanya</h6>
          </div>

          <table class="table table-bordered table-sm">
            <tr>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Nama</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_name }}</p>
                </div>
              </td>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>No Selular</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_phone_number }}</p>
                </div>
              </td>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Hubungan Keluarga</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_hubungan_keluarga }}</p>
                </div>
              </td>
            </tr>
          </table>

          <table class="table table-bordered table-sm mt-2">
            <tr>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Metode</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_metode }}</p>
                </div>
              </td>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Uraian Pertanyaan</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_uraian_pertanyaan }}</p>
                </div>
              </td>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Jenis Pertanyaan</h4>
                  <ul class="mb-0 pl-3">
                    <li v-for="(v,k) in dataModal.dataPelayanan.aranpio_jenis_pertanyaan" :key="`jp-${k}`">{{ v.label }} {{ v.note ? `, ${v.note}`: '' }}</li>
                  </ul>
                </div>
              </td>
            </tr>
          </table>

          <table class="table table-bordered table-sm mt-2">
            <tr>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Jawaban</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_jawaban }}</p>
                </div>
              </td>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Referensi</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_referensi }}</p>
                </div>
              </td>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Penyampaian Jawaban</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_penyampaian_jawaban }}</p>
                </div>
              </td>
            </tr>
          </table>

          <table class="table table-bordered table-sm mt-2">
            <tr>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Apoteker yang Menjawab</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_apoteker_menjawab }}</p>
                </div>
              </td>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Tanggal & Jam</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_date | moment("D MMMM Y") }} {{ dataModal.dataPelayanan.aranpio_time }}</p>
                </div>
              </td>
              <td width="33%" class="align-top">
                <div class="result_tab">
                  <h4>Metode Jawaban</h4>
                  <p>{{ dataModal.dataPelayanan.aranpio_metode_jawaban }}</p>
                </div>
              </td>
            </tr>
          </table>
          
        </div>

        <div class="col-md-8">
          <div class="card-header bg_head_panel mt-3">
            <h6 class="card-title font-weight-semibold">Retur Obat</h6>
          </div>

          <div class="table-responsive">
            <table class="table table-bordered table-sm">
              <tbody>
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Nama</th>
                    <th>Jumlah</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(v, k) in dataModal.dataReturObat" :key="`dmro-${k}`">
                    <td width="13%">{{ k+1 }}</td>
                    <td width="74%">{{ v.aranro_nama }}</td>
                    <td width="13%">{{ v.aranro_jumlah }}</td>
                  </tr>
                </tbody>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal hide-footer id="formReturObat" size="md" title="Retur Obat">
      <validation-observer ref="VFormRetur">
        <b-form @submit.prevent="doSubmitRetur()">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered table-striped table-sm patient-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Obat</th>
                    <th>Jumlah</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v, k) in returObat" :key="`retur-${k}`">
                    <td>{{ k+1 }}</td>
                    <td>
                      <v-select style="flex: 1;" class="med-selection select-paging" placeholder="Pilih Item" :class="obatLen <= 5 ? 'sp-col diagnosa-section': 'sp-col'" @open="countLen" @search="filterObat($event,k);countLen();" v-model="returObat[k].item_id" :options="mObatPreferensi" label="text" @input="selectJumlahSatuan($event,k); updateRowRetur(v);" :clearable="true" :reduce="v=>v.value" :selectable="v=>v.value !== 'pd' && v.value !== 'ol'">
                        <template slot="option" slot-scope="option">
                          <span v-if="option.preferensi"><b>{{ option.text }}</b></span>
                          <span v-else>{{ option.text }}</span>
                        </template>
                        <template #list-footer>
                          <li class="list-paging">
                            <div class="d-flex align-items-center justify-content-between">
                              <b-button @click="changePageObat(v.currentPage,k,'min','returObat')" size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                <i class="icon-arrow-left22 text-white"></i></b-button>
                              <small>Halaman {{v.currentPage}} dari
                                {{ceilData(v.totalRows/10)}}</small>
                              <b-button @click="changePageObat(v.currentPage,k,'plus','returObat')" size="sm" variant="transparent" class="btn-icon bg-blue-300">
                                <i class="icon-arrow-right22 text-white"></i></b-button>
                            </div>
                          </li>
                        </template>
                      </v-select>

                      <VValidate name="Obat" :vid="`ob-${k}`" v-model="v.item_id" :rules="{ required: 1 }" />
                    </td>
                    <td>
                      <div class="input-group input-group-sm">
                        <b-form-input type="text" class="form-control form-control-sm" placeholder="e.g. 15" fdprocessedid="ia3m4d" @change="updateRowRetur(v)" v-model="v.jumlah" :formatter="Formatter.number" />
                        <div class="input-group-append"><span class="input-group-text">{{ v.satuan }}</span></div>
                      </div>

                      <VValidate name="Jumlah" :vid="`jum-${k}`" v-model="v.jumlah" :rules="{ required: 1 }" />
                    </td>
                    <td>
                      <a href="javascript:;" v-if="returObat.length > 1" @click="removeReturObat(v, k)" v-b-tooltip.hover title="Hapus" class="btn btn-sm btn-icon border-danger rounded-round text-danger-800 alpha-danger" data-original-title="Hapus" aria-describedby="tooltip576336"><i class="icon-trash"></i></a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td class="text-center" colspan="3">
                      <a href="javascript:;" class="btn btn-outline-info alpha-info text-blue-800 btn-sm" @click="addReturObat()">
                        <i class="mr-2 icon-plus2"></i>
                        <span>Tambah</span>
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <div class="col-md-12 text-right mt-3">
              <button type="button" class="btn btn-link" @click="$bvModal.hide('formReturObat')">Cancel</button>
              <button type="submit" class="btn bg-primary">Submit</button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>

  <div v-else>
    <Form />
  </div>

</div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Form from './Form.vue'
import Formatter from '@/libs/Formatter.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

const moment = require('moment')

// import Datepicker from 'vuejs-datepicker'

const _ = global._
import $ from 'jquery'
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  components: {
    DateRangePicker,
    Form
  },
  computed: {
    isDetail() {
      return this.$route.query.isDetail
    },
    isDoc() {
      return this.$route.query.isDoc
    },
    passToSub() {
      return {
        Config: this.Config,
        mrValidation: this.mrValidation,
        rowPasien: this.rowPasien,
        isShowBangsal: this.isShowBangsal,
        infoBangsal: this.infoBangsal,
        katalogBangsal: this.katalogBangsal
      }
    },
  },
  data() {
    return {
      idKey: 'aranres_id',
      datePickerConfig: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
        autoApply: true,
        ranges: {
          'Hari Ini': [new Date(), new Date()],
          '7 Hari Terakhir': [new Date(moment().subtract(7, 'days')), new Date()],
          '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
          applyLabel: 'Terapkan',
          cancelLabel: 'Batal',
          direction: 'ltr',
          format: 'mm/dd/yyyy',
          separator: ' - ',
        }
      },
      dateRange: {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
      },

      rowEdit: {},
      rowPasien: {},
      rowAdditional: {},
      mRanapBangsal: [],
      mPPJP: [],
      mObatPreferensi: [],
      mrValidation: {},
      mrHubunganKeluarga: [],
      mrJenisPertanyaan: [],
      mrPenyampaianJawaban: [],
      mrMetode: [],
      mrMetodeJawaban: [],

      dataModal: {
        dataPelayanan: {}
      },
      returObat: [{}],
      obatLen: 0,
      currentPage: 1,

      Formatter
    }
  },
  mounted() {
    if (this.isList) {
      this.filter.status_jaminan = 'ALL'
      this.filter.cara_masuk = 'ALL'
      this.filter.ranap_bangsal = this.user.bu_bangsal_id

      this.doConvertDate()
      this.apiGet()

      if (this.isList) {
        this.apiGetResume()
      }

      setTimeout(() => {
        this.setSlide()
      }, 1000)
    } else {
      this.getFormPelayananInformasiObat()
    }
  },
  methods: {
    doSubmitPelayanan(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
          }
        },
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$router.push({
              name: this.modulePage
            })
          }
        })
      }).catch(() => {
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },
    downloadPelayanan(data){
      this.$swal.fire({
        title: 'Processing...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            ...data,
            type: "download-pelayanan-informasi",
            id_registrasi: data.aranr_id,
          }
        },
        "POST"
      )
      .then(res => {
        const linkSource = res.data
        const downloadLink = document.createElement("a")
        downloadLink.href = linkSource
        downloadLink.download = `Pelayanan-Informasi-Obat-${new Date().getTime()}.pdf`
        downloadLink.click()

        this.$swal.close()
      })
      .catch(()=>{
        this.$swal.close()
        // this.globalVar.apiGenError( err.response.data )
      })
    },
    openDetail(data) {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: 'get-detail',
            id_registrasi: data.aranr_id
          }
        },
      ).then(res => {
        const { dataPelayanan, dataReturObat } = res.data

        this.$set(this, 'dataModal', {
          dataPelayanan,
          dataReturObat
        })
        this.$bvModal.show('detail')
      })
    },

    getFormPelayananInformasiObat() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: 'get-form-pelayanan-informasi-obat',
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res => {
        _.forEach(res.data.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },

    doSubmitRetur() {
      this.$refs['VFormRetur'].validate().then(success => {
        if (!success) return false
        this.loadingOverlay = true

        setTimeout(() => {
          this.loadingOverlay = false
          this.$swal({
            icon: 'success',
            title: 'Berhasil Submit Retur Obat',
          }).then(() => {
            this.$bvModal.hide('formReturObat')
          })
        }, 700)
      })
    },
    updateRowRetur(data) {
      setTimeout(() => {
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              ...data,
              id_registrasi: this.dataModal.aranr_id,
              type: 'update-retur-obat'
            }
          },
          "POST"
        ).then(res => {
          if (!data.id) {
            this.$set(data, 'id', res.data.data.aranro_id)
          }
        })
      }, 1000)
    },
    addReturObat() {
      this.returObat.push({})
    },
    removeReturObat(data, index) {
      if (data.id) {
        Gen.apiRest(
          "/do/" + this.modulePage, {
            data: {
              ...data,
              id_registrasi: this.dataModal.aranr_id,
              type: 'remove-retur-obat'
            }
          },
          "POST"
        ).then(() => {
          this.returObat.splice(index, 1)
        })
      } else {
        this.returObat.splice(index, 1)
      }
    },
    openReturObat(data) {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: 'get-form-obat',
            id_registrasi: data.aranr_id
          }
        },
      ).then(res => {
        this.$set(this, 'dataModal', { ...data, dataPelayanan: {} })

        this.$set(this, 'mObatPreferensi', res.data.mObatPreferensi)
        this.$set(this, 'returObat', res.data.returObat)
        this.$bvModal.show('formReturObat')
      })
    },
    selectJumlahSatuan(e, k) {
      let data = {
        id: e,
        type: 'get-by-obat'
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.$set(this.returObat[k], 'satuan', res.data.satuan)
        this.$set(this.returObat[k], 'nama', res.data.nama)
      })
    },
    changePageObat(page, k, act, col) {
      if (act == 'min') {
        if (page > 1) {
          page -= 1
          this[col][k]['currentPage'] -= 1
        }
      } else {
        if (page < this.ceilData(this[col][k]['totalRows'] / 10)) {
          page += 1
          this[col][k]['currentPage'] += 1
        }
      }

      let data = {
        type: 'select-paging-obat',
        page: page,
        idDokter: this.dataModal.aranr_dokter_id,
        search: this[col][k]['search']
      }
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: data
        },
        "POST"
      ).then(res => {
        this.mObatPreferensi = res.data.data
      })
    },
    ceilData(value) {
      return Math.ceil(value)
    },
    countLen() {
      setTimeout(() => {
        this.obatLen = $('.sp-col ul li').length
      }, 100)
    },
    toMonitoring(regID) {
      this.$router.push({
        name: 'RanapMonitoring',
        params: {
          pageSlug: regID
        }
      }).catch(() => {})
    },
    setSlide() {
      const responsiveTable = document.querySelector('.table-responsive')
      if (responsiveTable) {
        const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
        const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
        if (responsiveTableScroll) {
          // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
          Array.from(responsiveTableScrollActions).forEach(action => {
            action.addEventListener('click', e => {
              const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100

              responsiveTable.scroll({
                left: scrollAmount,
                behavior: 'smooth'
              })
            })
          })
        }
      }
    },
    doResetData() {
      this.doReset()
      this.dateRange = {
        startDate: new Date(moment().subtract(7, 'days')),
        endDate: new Date(),
      }
      this.doConvertDate()
    },
    doRefreshData() {
      this.apiGet()
      window.scrollTo(0, 0)
    },
    apiGetResume() {
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      Gen.apiRest(
        "/get/" + this.modulePage + '/resume', {
          params: Object.assign({}, paramsQuery || {})
        }
      ).then(res => {
        _.forEach(res.data, (v, k) => {
          this.$set(this, k, v)
        })
      })
    },
    doConvertDate() {
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne() {
      this.filter.page = 1
    },
    doFill() {
      this.doPageOne()
      this.doFilter()
    },
    updateValues(e) {
      this.doConvertDate()
      this.doFill()
    },
    doSearch: _.debounce(function () {
      this.doPageOne()
      this.doFilter()
    }, 500),
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master || []).findIndex(x => x.value == value)
        if (index !== -1) {
          text = master[index]['text']
        }
      }
      return text
    },
    to24Hours(date) {
      return moment(date).add(24, 'hours').format('YYYY-MM-DD')
    },
    validationTime(regDate) {
      if (moment().format('YYYY-MM-DD') >= this.to24Hours(regDate)) {
        return true
      } else {
        return false
      }
    },

    filterObat: _.debounce(function (e, k) {
      if (e) {
        this.returObat[k]['search'] = e
        let id = []
        for (let i = 0; i < (this.returObat || []).length; i++) {
          if (this.returObat[i]['item_id'] && this.returObat[i]['jenis'] == 1) {
            id.push(this.returObat[i]['item_id'])
          }
        }
        Gen.apiRest('/do/' + this.modulePage, {
          data: {
            type: 'select-obat',
            search: e,
            id: id
          }
        }, 'POST').then(res => {
          this.mObatPreferensi = res.data.data
          this.returObat[k]['currentPage'] = 1
          this.returObat[k]['totalRows'] = res.data.totalRows
        })
      }
    }, 10),
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch: {
    $route() {
      if (this.isList) {
        this.apiGet()
        this.apiGetResume()

        setTimeout(() => {
          if (this.isList) {
            this.setSlide()
          }
        }, 1000)
      } else {
        this.getFormPelayananInformasiObat()
      }
    },
    '$route.params.pageSlug': function () {
      this.$set(this, 'filter', {})
    },
    'openPerawatPelaksana': function (v) {
      if (!v) {
        this.apiGet()
      }
    }
  }
}
</script>
